export const GET_RTM_PAIN_MANAGEMENT_READING_IDS_REQUEST =
  "GET_RTM_PAIN_MANAGEMENT_READING_IDS_REQUEST"
export const GET_RTM_PAIN_MANAGEMENT_READING_IDS_SUCCESS =
  "GET_RTM_PAIN_MANAGEMENT_READING_IDS_SUCCESS"
export const GET_RTM_PAIN_MANAGEMENT_READING_IDS_FAIL = "GET_RTM_PAIN_MANAGEMENT_READING_IDS_FAIL"

export const GET_RTM_PAIN_MANAGEMENT_SINGLE_READING_REQUEST =
  "GET_RTM_PAIN_MANAGEMENT_SINGLE_READING_REQUEST"
export const GET_RTM_PAIN_MANAGEMENT_SINGLE_READING_SUCCESS =
  "GET_RTM_PAIN_MANAGEMENT_SINGLE_READING_SUCCESS"
export const GET_RTM_PAIN_MANAGEMENT_SINGLE_READING_FAIL =
  "GET_RTM_PAIN_MANAGEMENT_SINGLE_READING_FAIL"

export const GET_RTM_MENTAL_HEALTH_READING_IDS_REQUEST = "GET_RTM_MENTAL_HEALTH_READING_IDS_REQUEST"
export const GET_RTM_MENTAL_HEALTH_READING_IDS_SUCCESS = "GET_RTM_MENTAL_HEALTH_READING_IDS_SUCCESS"
export const GET_RTM_MENTAL_HEALTH_READING_IDS_FAIL = "GET_RTM_MENTAL_HEALTH_READING_IDS_FAIL"

export const GET_RTM_MENTAL_HEALTH_SINGLE_READING_REQUEST =
  "GET_RTM_MENTAL_HEALTH_SINGLE_READING_REQUEST"
export const GET_RTM_MENTAL_HEALTH_SINGLE_READING_SUCCESS =
  "GET_RTM_MENTAL_HEALTH_SINGLE_READING_SUCCESS"
export const GET_RTM_MENTAL_HEALTH_SINGLE_READING_FAIL = "GET_RTM_MENTAL_HEALTH_SINGLE_READING_FAIL"

export const GET_RTM_ADDICTION_MEDICINE_READING_IDS_REQUEST =
  "GET_RTM_ADDICTION_MEDICINE_READING_IDS_REQUEST"
export const GET_RTM_ADDICTION_MEDICINE_READING_IDS_SUCCESS =
  "GET_RTM_ADDICTION_MEDICINE_READING_IDS_SUCCESS"
export const GET_RTM_ADDICTION_MEDICINE_READING_IDS_FAIL =
  "GET_RTM_ADDICTION_MEDICINE_READING_IDS_FAIL"

export const GET_RTM_ADDICTION_MEDICINE_SINGLE_READING_REQUEST =
  "GET_RTM_ADDICTION_MEDICINE_SINGLE_READING_REQUEST"
export const GET_RTM_ADDICTION_MEDICINE_SINGLE_READING_SUCCESS =
  "GET_RTM_ADDICTION_MEDICINE_SINGLE_READING_SUCCESS"
export const GET_RTM_ADDICTION_MEDICINE_SINGLE_READING_FAIL =
  "GET_RTM_ADDICTION_MEDICINE_SINGLE_READING_FAIL"
