import { axiosGet, axiosPost } from "../axios";

import {
    PATIENT_HEALTH_KPI_FAIL,
    PATIENT_HEALTH_KPI_REQUEST,
    PATIENT_HEALTH_KPI_SUCCESS,
    PATIENT_BP_REQUEST,
    PATIENT_BP_SUCCESS,
    PATIENT_BP_FAIL,
    PATIENT_OXY_REQUEST,
    PATIENT_OXY_SUCCESS,
    PATIENT_OXY_FAIL,
    PATIENT_GLUCOSE_FAIL,
    PATIENT_GLUCOSE_REQUEST,
    PATIENT_GLUCOSE_SUCCESS,
    PATIENT_READING_REQUEST,
    PATIENT_READING_SUCCESS,
    PATIENT_READING_FAIL,
    PATIENT_INFO_REQUEST,
    PATIENT_INFO_SUCCESS,
    PATIENT_INFO_FAIL,
    PATIENT_TIMELINE_REQUEST,
    PATIENT_TIMELINE_SUCCESS,
    PATIENT_TIMELINE_FAIL,
    PATIENT_OXYR_REQUEST,
    PATIENT_OXYR_SUCCESS,
    PATIENT_OXYR_FAIL,
    PATIENT_BPR_REQUEST,
    PATIENT_BPR_SUCCESS,
    PATIENT_BPR_FAIL,
    PATIENT_WMR_REQUEST,
    PATIENT_WMR_SUCCESS,
    PATIENT_WMR_FAIL,
    ADD_PATIENT_REQUEST,
    ADD_PATIENT_SUCCESS,
    ADD_PATIENT_FAIL,
    ADD_MANUAL_TIME_REQUEST,
    ADD_MANUAL_TIME_FAIL,
    ADD_MANUAL_TIME_SUCCESS,
    GET_MANUAL_TIME_FAIL,
    GET_MANUAL_TIME_REQUEST,
    GET_MANUAL_TIME_SUCCESS,
    GET_CHRONIC_CONDITION_REQUEST,
    GET_CHRONIC_CONDITION_SUCCESS,
    GET_CHRONIC_CONDITION_FAIL,
    CRITICAL_STATUS_REQUEST,
    CRITICAL_STATUS_SUCCESS,
    CRITICAL_STATUS_FAIL,
    CHRONIC_TYPE_REQUEST,
    CHRONIC_TYPE_SUCCESS,
    CHRONIC_TYPE_FAIL,
    POST_CHRONIC_DATA_SUCCESS,
    POST_CHRONIC_DATA_REQUEST,
    POST_CHRONIC_DATA_FAIL,
    UPDATE_PATIENT_REQUEST,
    UPDATE_PATIENT_FAIL,
    UPDATE_PATIENT_SUCCESS,
    GET_REPORT_DATA_REQUEST,
    GET_REPORT_DATA_SUCCESS,
    GET_REPORT_DATA_FAIL, PATIENT_WM_FAIL, PATIENT_WM_REQUEST, PATIENT_WM_SUCCESS,
    GET_PATIENT_MANUAL_LOG_REQUEST,
    GET_PATIENT_MANUAL_LOG_SUCCESS,
    GET_PATIENT_MANUAL_LOG_FAIL,
    PATIENT_VITALS_REQUEST,
    PATIENT_VITALS_SUCCESS,
    PATIENT_VITALS_FAIL,
    GET_PATIENT_CARETAKER_REQUEST,
    GET_PATIENT_CARETAKER_SUCCESS,
    GET_PATIENT_CARETAKER_FAIL,
    PATIENT_MONITORING_REQUEST,
    PATIENT_MONITORING_SUCCESS,
    PATIENT_MONITORING_FAIL,
    PATIENT_CRITICAL_DEVICES_REQUEST,
    PATIENT_CRITICAL_DEVICES_SUCCESS,
    PATIENT_CRITICAL_DEVICES_FAIL,
    PATIENT_DIAGNOSTIC_REQUEST,
    PATIENT_DIAGNOSTIC_SUCCESS,
    PATIENT_DIAGNOSTIC_FAIL,
} from "../types/PatientConstant";
import { userLogoutAction } from './userActions';

export const getPatientDiagnosticsData = patientId => async dispatch => {
    try {
      dispatch({
        type: PATIENT_DIAGNOSTIC_REQUEST
      })
      const { data } = await axiosGet(`/api/cs/patients/diagnostic/${patientId}/fetch`)
      dispatch({
        type: PATIENT_DIAGNOSTIC_SUCCESS,
        payload: data.details
      })
    } catch (error) {
      console.log("error", error)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: PATIENT_DIAGNOSTIC_FAIL,
        payload: error.response && error?.response?.data?.error
      })
    }
  }

// get patient's critical device(s) in v2
export const getPatientCriticalDevices = patientId => async dispatch => {
    try {
      dispatch({
        type: PATIENT_CRITICAL_DEVICES_REQUEST
      })
  
      const { data } = await axiosPost(`/api/rpm2/readings/critical/${patientId}/fetch`)
  
      dispatch({
        type: PATIENT_CRITICAL_DEVICES_SUCCESS,
        payload: data.details
      })
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: PATIENT_CRITICAL_DEVICES_FAIL,
        payload: error?.response && error?.response?.data?.error
      })
    }
  }

export const getPatientMonitoringData = patientId => async dispatch => {
    try {
      dispatch({
        type: PATIENT_MONITORING_REQUEST
      })
  
      const { data } = await axiosGet(`/api/cs/patients/monitoring/${patientId.patientId}/fetch`)
  
      dispatch({
        type: PATIENT_MONITORING_SUCCESS,
        payload: data.details
      })
    } catch (error) {
      console.log("error", error)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: PATIENT_MONITORING_FAIL,
        payload: error.response && error?.response?.data?.error
      })
    }
  }

export const getPatientCaretaker = patientID => async dispatch => {
    try {
      dispatch({
        type: GET_PATIENT_CARETAKER_REQUEST
      })
      const { data } = await axiosGet(`/api/cs/patients/${patientID}/caretaker/fetchAll`)
      dispatch({
        type: GET_PATIENT_CARETAKER_SUCCESS,
        payload: data.details
      })
    } catch (error) {
      console.log("error", error)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: GET_PATIENT_CARETAKER_FAIL,
        payload: error.response && error?.response?.data?.error
      })
    }
  }

export const getManualLogTrack = (patientId, month, year) => async dispatch => {
  try {
    dispatch({
      type: GET_PATIENT_MANUAL_LOG_REQUEST
    })
    let payload
    if (month !== undefined && year !== undefined) {
      payload = { month, year }
    } else {
      payload = {
        month: new Date().getMonth() + 1,
        year: new Date().getFullYear()
      }
    }
    // const payload = { month: new Date().getMonth() + 1, year: new Date().getFullYear(), userId: patientId.patientId };
    const { data } = await axiosGet(
      `/api/cs/patients/manualLogTrack/${patientId}/fetch/${payload.month}/${payload.year}`
    )

    dispatch({
      type: GET_PATIENT_MANUAL_LOG_SUCCESS,
      payload: data.details
    })
    return data
  } catch (error) {
    //   console.log("error", error)
    error?.response?.status === 401 && dispatch(userLogoutAction())
    dispatch({
      type: GET_PATIENT_MANUAL_LOG_FAIL,
      payload: error.response && error?.response?.data?.details
    })
  }
}

export const postAddPatient =
    ({
         countryCode,
         phoneNumber,
         address,
         email,
         firstName,
         lastName,
         middleName,
         dob,
         gender,
         userName,
         profileImage,
         orgId,
         moniterDetails,
         primaryAdminId,
         caretakerId,
         vitalDetails,
         diagnosticData,
    }) =>
    async (dispatch) => {
        try {
            dispatch({
                type: ADD_PATIENT_REQUEST,
            });

            let formData = {
                countryCode,
                phoneNumber,
                address,
                email,
                firstName,
                lastName,
                middleName,
                dob,
                gender,
                userName,
                profileImage,
                orgId,
                moniterDetails,
                primaryAdminId,
                caretakerId,
                vitalDetails,
                diagnosticData,
            };

            const { data } = await axiosPost(`/users/v1/registerUser`, formData,{headers:{
                'Content-Type': 'application/json'
                }});

            dispatch({
                type: ADD_PATIENT_SUCCESS,
                payload: JSON.stringify(data),
            });

            return data;
        } catch (error) {
            dispatch({
                type: ADD_PATIENT_FAIL,
                payload: error.response && error.response.data.message ? error.response.data.message : error.message,
            });
            error?.response?.status === 401 && dispatch(userLogoutAction())
            return error.response;
        }
    };

export const getPatientHealthKpi = (patientId) => async (dispatch) => {
    try {
        dispatch({
            type: PATIENT_HEALTH_KPI_REQUEST,
        });

        const { data } = await axiosGet(`v1/getPatientDetailsKPIForProviderDB?providerId=${patientId.patientId}`);

        dispatch({
            type: PATIENT_HEALTH_KPI_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: PATIENT_HEALTH_KPI_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
        error?.response?.status === 401 && dispatch(userLogoutAction())
    }
};


export const getWmDetails =
  ({ patientId, startDate, endDate, programId }) =>
  async dispatch => {
    try {
      dispatch({
        type: PATIENT_WM_REQUEST
      })

      const { data } = await axiosPost(`/api/rpm/readings/${programId}/wm/${patientId}/fetch/100`, {
        startDate,
        endDate
      })

      dispatch({
        type: PATIENT_WM_SUCCESS,
        payload: data?.details
      })
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: PATIENT_WM_FAIL,
        payload: error.response && error?.response?.data?.detail
      })
    }
  }

export const getBpDetails =
  ({ patientId, startDate, endDate, programId }) =>
  async dispatch => {
    try {
      dispatch({
        type: PATIENT_BP_REQUEST
      })

      const { data } = await axiosPost(`/api/rpm/readings/${programId}/bp/${patientId}/fetch/100`, {
        startDate,
        endDate
      })

      dispatch({
        type: PATIENT_BP_SUCCESS,
        payload: data.details
      })
    } catch (error) {
      console.debug("error", error)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: PATIENT_BP_FAIL,
        payload: error?.response && error.response?.data?.details
      })
    }
  }

export const getOxyDetails =
  ({ patientId, startDate, endDate, programId }) =>
  async dispatch => {
    try {
      dispatch({
        type: PATIENT_OXY_REQUEST
      })

      const { data } = await axiosPost(
        `/api/rpm/readings/${programId}/oximeter/${patientId}/fetch/100`,
        {
          startDate,
          endDate
        }
      )

      dispatch({
        type: PATIENT_OXY_SUCCESS,
        payload: data.details
      })
    } catch (error) {
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: PATIENT_OXY_FAIL,
        payload: error.response && error?.response?.data?.details
      })
    }
  }

export const getGlucoseDetails =
  ({ patientId, startDate, endDate, programId }) =>
  async dispatch => {
    try {
      dispatch({
        type: PATIENT_GLUCOSE_REQUEST
      })

      const { data } = await axiosPost(
        `/api/rpm/readings/${programId}/glucose/${patientId}/fetch/100`,
        {
          startDate,
          endDate
        }
      )

      dispatch({
        type: PATIENT_GLUCOSE_SUCCESS,
        payload: data.details
      })
    } catch (error) {
      console.debug(error)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: PATIENT_GLUCOSE_FAIL,
        payload: error.response && error?.response?.data?.details
      })
    }
  }


export const getLatestReadings = (patientId) => async (dispatch) => {
    try {
        dispatch({
            type: PATIENT_READING_REQUEST,
        });

        const config = { headers: { 'Content-Type': 'application/json' } };
        const payload = {month: new Date().getMonth(), year: new Date().getFullYear(),userId:patientId.patientId}
        const { data } = await axiosPost(`/admin/v1/getReadingCount`,payload,config);

        dispatch({
            type: PATIENT_READING_SUCCESS,
            payload: data.count,
        });
    } catch (error) {
        // dispatch({
        //     type: PATIENT_READING_FAIL,
        //     payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        // });
        // error?.response?.status === 401 && dispatch(userLogoutAction())
    }
};

export const getPatientVitals = patientId => async dispatch => {
    try {
      dispatch({
        type: PATIENT_VITALS_REQUEST
      })
  
      const { data } = await axiosGet(`/api/cs/patients/vital/${patientId}/fetch`)
  
      dispatch({
        type: PATIENT_VITALS_SUCCESS,
        payload: data.details
      })
    } catch (error) {
      console.log("error", error)
      error?.response?.status === 401 && dispatch(userLogoutAction())
      dispatch({
        type: PATIENT_VITALS_FAIL,
        payload: error.response && error?.response?.data?.error
      })
    }
}

export const getPatientInfo = ({patientId}) => async (dispatch) => {
    try {
        dispatch({
            type: PATIENT_INFO_REQUEST,
        });

        // const { data } = await axiosGet(`/providers/v2/getUserProfile?memberId=${patientId.patientId}`);
        const { data } = await axiosGet(`/api/cs/patients/${patientId}/profile`)

        dispatch({
            type: PATIENT_INFO_SUCCESS,
            payload: data?.details,
        });
    } catch (error) {
        error?.response?.status === 401 && dispatch(userLogoutAction())
        dispatch({
            type: PATIENT_INFO_FAIL,
            payload: error.response && error?.response?.data?.error
        })
    }
};

export const getPatientTimeLine = (patientId) => async (dispatch) => {
    try {
        dispatch({
            type: PATIENT_TIMELINE_REQUEST,
        });

        const { data } = await axiosGet(`v1/getPatientKPIAndTimelines?memberId=${patientId.patientId}`);

        dispatch({
            type: PATIENT_TIMELINE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: PATIENT_TIMELINE_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
        error?.response?.status === 401 && dispatch(userLogoutAction())
    }
};

export const getPatientOxyReading = (patientId) => async (dispatch) => {
    try {
        dispatch({
            type: PATIENT_OXYR_REQUEST,
        });

        const { data } = await axiosGet(`v1/getMemberReadingsForOMDB?memberId=${patientId.patientId}`);

        dispatch({
            type: PATIENT_OXYR_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: PATIENT_OXYR_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
        error?.response?.status === 401 && dispatch(userLogoutAction())
    }
};

export const getPatientBPReading = (patientId) => async (dispatch) => {
    try {
        dispatch({
            type: PATIENT_BPR_REQUEST,
        });

        const { data } = await axiosGet(`v1/getMemberReadingsForBPMDB?memberId=${patientId.patientId}`);

        dispatch({
            type: PATIENT_BPR_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: PATIENT_BPR_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
        error?.response?.status === 401 && dispatch(userLogoutAction())
    }
};

// GET PATIENT WEIGHT MACHINE READING
export const getPatinetWmReading = (patientId) => async (dispatch) => {
    try {
        dispatch({
            type: PATIENT_WMR_REQUEST,
        });

        const { data } = await axiosGet(`v1/getMemberReadingsDB?memberId=${patientId.patientId}`);

        dispatch({
            type: PATIENT_WMR_SUCCESS,
            payload: data,
        });
    } catch (error) {
        dispatch({
            type: PATIENT_WMR_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
        error?.response?.status === 401 && dispatch(userLogoutAction())
    }
};

// POST ADD MANUAL TIME
export const AddManualTimeAction = (patientId, program, care, dateTime, duration, notes) => async (dispatch) => {
    try {
        dispatch({
            type: ADD_MANUAL_TIME_REQUEST,
        });

        let formData = {
            userId: patientId,
            program: [program],
            care: care,
            careTime: duration.toString(),
            careDate: dateTime,
            notes: notes,
        };

        const { data } = await axiosPost("/admin/v1/postManualLogDetails", formData);
        dispatch({
            type: ADD_MANUAL_TIME_SUCCESS,
            payload: data,
        });
        return data;
    } catch (error) {
        // dispatch({
        //     type: ADD_MANUAL_TIME_FAIL,
        //     payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        // });
        // error?.response?.status === 401 && dispatch(userLogoutAction())
    }
};

// GET MANUAL TIME DATA
export const getManualTimeAction = (patientId) => async (dispatch) => {
    try {
        dispatch({
            type: GET_MANUAL_TIME_REQUEST,
        });

        const { data } = await axiosGet(`/admin/v1/getManualLogDetails?memberId=${patientId}`);

        dispatch({
            type: GET_MANUAL_TIME_SUCCESS,
            payload: data?.manualLog,
        });
    } catch (error) {
        // dispatch({
        //     type: GET_MANUAL_TIME_FAIL,
        //     payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        // });
        // error?.response?.status === 401 && dispatch(userLogoutAction())
    }
};

// GET MANUAL TIME DATA
export const getChronicConditionAction = (patientId) => async (dispatch) => {
    try {
        dispatch({
            type: GET_CHRONIC_CONDITION_REQUEST,
        });

        const { data } = await axiosGet(`/providers/v1/getDiagnosticData?memberId=${patientId}`);

        dispatch({
            type: GET_CHRONIC_CONDITION_SUCCESS,
            payload: data.diagnosticData,
        });
    } catch (error) {
        dispatch({
            type: GET_CHRONIC_CONDITION_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
        error?.response?.status === 401 && dispatch(userLogoutAction())
    }
};

export const criticalStatusAction = (criticalStatus, patientId) => async (dispatch) => {
    try {
        dispatch({
            type: CRITICAL_STATUS_REQUEST,
        });

        const { data } = await axiosPost(`v1/updateIsCritical`, {
            isCritical: criticalStatus,
            patientId: patientId,
        });

        dispatch({
            type: CRITICAL_STATUS_SUCCESS,
            payload: data,
        });

        if (data.success) {
            window.location.reload();
        }
    } catch (error) {
        dispatch({
            type: CRITICAL_STATUS_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
        error?.response?.status === 401 && dispatch(userLogoutAction())
    }
};

export const getChronicTypeListAction = (memberId) => async (dispatch) => {
    try {
        dispatch({
            type: CHRONIC_TYPE_REQUEST,
        });

        const { data } = await axiosGet(`/providers/v1/getDiagnosticData?memberId=${memberId}`);

        dispatch({
            type: CHRONIC_TYPE_SUCCESS,
            payload: data,
        });
    } catch (error) {
        // dispatch({
        //     type: CHRONIC_TYPE_FAIL,
        //     payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        // });
        // error?.response?.status === 401 && dispatch(userLogoutAction())
    }
};

export const postChronicConditionAction = (memberId, chronicCondition) => async (dispatch) => {
    try {
        dispatch({
            type: POST_CHRONIC_DATA_REQUEST,
        });

        const { data } = await axiosPost(`/admin/v1/postDiagnosticData`, {
            userId: memberId.toString(),
            diagnosticData: chronicCondition,
        });

        dispatch({
            type: POST_CHRONIC_DATA_SUCCESS,
            payload: data,
        });

        return data;
    } catch (error) {
        // dispatch({
        //     type: POST_CHRONIC_DATA_FAIL,
        //     payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        // });
        // error?.response?.status === 401 && dispatch(userLogoutAction())
    }
};

export const UpdatePatientAction =
    ({
        email,
        phoneNumber,
        firstName,
        lastName,
        deviceType,
        deviceUDID,
        language,
        weightUnit,
        heightUnit,
        profileImg,
        address,
        orgId,
        loginId,
        gender,
        dob,
        insuranceNumber,
        weightValue,
        heightValue,
        careTakerId,
        // providerId,
        memberId,
        // deviceIds,
        moniterId,
        moniteringDetails,
        diagnosticCodes,
        // moniteringDetails
    }) =>
    async (dispatch) => {
        try {
            dispatch({
                type: UPDATE_PATIENT_REQUEST,
            });

            let formData = {
                email,
                phoneNumber,
                firstName,
                lastName,
                deviceType,
                deviceUDID,
                language,
                weightUnit,
                heightUnit,
                profileImg,
                location: "",
                address,
                orgId,
                loginId,
                gender,
                dob,
                insuranceNumber,
                weightValue,
                heightValue,
                careTakerId,
                // providerId,
                memberId,
                // deviceIds,
                moniterId,
                moniteringDetails,
                diagnosticCodes,
            };

            console.log(formData);

            const { data } = await axiosPost(`v2/updateUserProfile`, formData);

            dispatch({
                type: UPDATE_PATIENT_SUCCESS,
                payload: JSON.stringify(data),
            });

            return data;
        } catch (error) {
            dispatch({
                type: UPDATE_PATIENT_FAIL,
                payload: error.response && error.response.data.message ? error.response.data.message : error.message,
            });
            error?.response?.status === 401 && dispatch(userLogoutAction())
        }
    };

//patient report generated
export const getPatientReportAction = (patientId, startDate, endDate) => async (dispatch) => {
    try {
        dispatch({
            type: GET_REPORT_DATA_REQUEST,
        });

        const { data } = await axiosGet(`v1/generatePDFData?memberId=${patientId}&startDate=${startDate}&endDate=${endDate}`);

        dispatch({
            type: GET_REPORT_DATA_SUCCESS,
            payload: {
                startDate: startDate,
                endDate: endDate,
                data,
            },
        });

        return data;
    } catch (error) {
        dispatch({
            type: GET_REPORT_DATA_FAIL,
            payload: error.response && error.response.data.message ? error.response.data.message : error.message,
        });
        error?.response?.status === 401 && dispatch(userLogoutAction())
    }
};
