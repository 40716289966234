import { Button, Col, InputNumber, Modal, Row, Slider } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
    getPatientGlucoCustomParameter,
    updatePatientGlucoCustomParameter,
    postPatientGlucoCustomParameter,
    deletePatientGlucoCustomParameter,
    resetPatientGlucoCustomParameter
} from "../../redux/actions/customParameterActions"
import { useToasts } from "react-toast-notifications"
import { getPatientInfo } from "../../redux/actions/patientAction"
import { ExclamationCircleFilled } from "@ant-design/icons"

const GLUCO_DELETE_SUCCESS_MSG = "Glucose meter custom parameters deleted successfully"
const GLUCO_DELETE_ERR_MSG = "Unable to delete Glucose Meter custom parameters"
const NORMAL_GLUCO_LOW_VALUE_MSG = "Glucose meter normal range should be greater than or equal to 50 mg/dl"
const NORMAL_GLUCO_HIGH_VALUE_MSG = "Glucose meter normal range should be less than or equal to 300 mg/dl"

const PatientGlucoCustomParameter = ({ patientId, setShowCustomParameter, isEdit, setIsEdit, setIsCustomParameter, setGlucoseCustomParameterTab }) => {
    const [glucoValues, setGlucoValues] = useState([49, 60, 140, 601])
    const [tempGlucoValues, setTempGlucoValues] = useState([49, 60, 140, 601])
    const [marksGlucoMeter, setMarksGlucoMeter] = useState({})
    const [showSaveModal, setShowSaveModal] = useState(false)
    const [showRemoveModal, setShowRemoveModal] = useState(false)
    const [disabledGluco, setDisabledGluco] = useState(true);
    const [glucoCustomError, setGlucoCustomError] = useState(false);

    const [glucoStageWidth, setGlucoStageWidth] = useState({
        Hypoglycemia: 20,
        Normal: 20,
        Hyperglycemia: 20
    });

    const dispatch = useDispatch();
    const { addToast } = useToasts();

    const { loading: GlucoCustomParameterLoading, glucoCustomParameters } = useSelector(state => state.getPatientGlucoCustomParameter)
    const { loading: postOxiCustomParameterLoading } = useSelector(state => state.postPatientOxiCustomParameter)
    const { loading: updateOxiCustomParameterLoading } = useSelector(state => state.updatePatientOxiCustomParameter)
    const { loading: deleteOxiCustomParameterLoading } = useSelector(state => state.deletePatientOxiCustomParameter)

    function calculatePercentage(start, end, minValue, maxValue) {
        const range = maxValue - minValue;
        const percentage = ((end - start) / range) * 100;
        return parseInt(percentage)
    }

    const handleSubmit = async () => {
        const formData = {
            "higherLimit": 601,
            "lowerLimit": 49,
            "normalLow": glucoValues[1],
            "normalHigh": glucoValues[2]
        }
        let result
        if (isEdit) {
            result = await dispatch(updatePatientGlucoCustomParameter(formData, patientId))
        } else {
            result = await dispatch(postPatientGlucoCustomParameter(formData, patientId))
        }
        if (result?.message.toLowerCase() === "success") {
            addToast(`Glucose Meter custom parameters ${isEdit ? "updated" : "added"} successfully`, {
                appearance: "success",
                autoDismiss: true
            })
            dispatch(getPatientInfo({ patientId }))
            setShowSaveModal(false);
            setIsCustomParameter(true);
            setGlucoseCustomParameterTab(true);
            setShowCustomParameter(false);
        } else {
            addToast(`Unable to ${isEdit ? "update" : "add"} Glucose meter custom parameters`, {
                appearance: "error",
                autoDismiss: true
            })
            setShowSaveModal(false);
        }
    }

    useEffect(() => {
        if (!glucoCustomParameters) {
            dispatch(getPatientGlucoCustomParameter(patientId))
        }
    }, [])

    useEffect(() => {
        if (!GlucoCustomParameterLoading) {
            if (glucoCustomParameters?.hasOwnProperty("normalLower")) {
                setGlucoseCustomParameterTab(true)
                setIsCustomParameter(true)
                let customGlucoValueArr = [];
                const glucoValuesOrder = ["lowerLimit", "normalLower", "normalHigher", "higherLimit"]
                for (let i = 0; i < glucoValuesOrder.length; i++) {
                    customGlucoValueArr.push(glucoCustomParameters && glucoCustomParameters[glucoValuesOrder[i]])
                }
                setGlucoValues(customGlucoValueArr);
            } else {
                setGlucoseCustomParameterTab(false)
                setGlucoValues([49, 60, 140, 601])
            }
        }
    }, [glucoCustomParameters])

    useEffect(() => {
        let firstToSecondPoint = calculatePercentage(glucoValues[0], glucoValues[1], glucoValues[0], glucoValues[glucoValues.length - 1]);
        let secondToThirdPoint = calculatePercentage(glucoValues[1], glucoValues[2], glucoValues[0], glucoValues[glucoValues.length - 1]);
        let thirdToFourthPoint = calculatePercentage(glucoValues[2], glucoValues[3], glucoValues[0], glucoValues[glucoValues.length - 1]);
        firstToSecondPoint = (firstToSecondPoint < 10) ? 10 : firstToSecondPoint
        secondToThirdPoint = (secondToThirdPoint > 40) ? 40 : secondToThirdPoint
        thirdToFourthPoint = (thirdToFourthPoint > 50) ? 50 : thirdToFourthPoint

        setGlucoStageWidth({
            ...glucoStageWidth,
            Hypoglycemia: firstToSecondPoint,
            Normal: secondToThirdPoint,
            Hyperglycemia: thirdToFourthPoint,
        })
    }, [glucoValues])

    useEffect(() => {
        let tempGlucoValues = [...glucoValues];
        if (tempGlucoValues[1] < 50) {
            setGlucoCustomError(NORMAL_GLUCO_LOW_VALUE_MSG)
        } else if (tempGlucoValues[2] > 300) {
            setGlucoCustomError(NORMAL_GLUCO_HIGH_VALUE_MSG)
        } else {
            setGlucoCustomError("")
        }
    }, [glucoValues])

    useEffect(() => {
        const defaultValues = [49, 60, 140, 601]
        for (let i = 0; i < glucoValues.length - 1; i++) {
            if (glucoValues[i] >= glucoValues[i + 1]) {
                setDisabledGluco(true)
                break
            }
            if (isEdit) {
                if (glucoValues[i] === tempGlucoValues[i]) {
                    setDisabledGluco(true)
                } else {
                    setDisabledGluco(false)
                    break
                }
            } else {
                if (glucoValues[i] === defaultValues[i]) {
                    setDisabledGluco(true)
                } else {
                    setDisabledGluco(false)
                    break
                }
            }
        }
    }, [glucoValues])

    const handleGlucoReadingChange = (e, index) => {
        let tempGlucoValues = [...glucoValues];
        if (e < tempGlucoValues[index]) {
            tempGlucoValues[index + 1] = tempGlucoValues[index] + (tempGlucoValues[index + 1] - tempGlucoValues[index]);
        } else {
            tempGlucoValues[index + 1] = e
        }
        setGlucoValues(tempGlucoValues)
    }

    useEffect(() => {
        const newGlucovalue = glucoValues.slice(1, -1)
        const newMarksGluco = newGlucovalue.reduce((acc, value, index) => {
            acc[value] = {
                label: (
                    <>
                        <InputNumber
                            value={value}
                            controls={false}
                            style={{
                                width: '50px',
                                height: '30px',
                                fontSize: '14px',
                                borderRadius: '4px',
                                border: '1px solid #ccc',
                            }}
                            onChange={(e) => handleGlucoReadingChange(e, index)}
                        />
                    </>
                ),
                style: {
                    position: "absolute",
                    top: "-50px",
                    fontSize: "16px"
                }
            };
            return acc;
        }, {});
        setMarksGlucoMeter(newMarksGluco)

    }, [glucoValues])

    const trackColors = ["#ff3b30", "#34c759", "#ff3b30"]

    const HandleShowSaveModal = () => {
        setShowSaveModal(true);
    }
    const HandleShowRemoveModal = () => {
        setShowRemoveModal(true);
    }

    const handleRemoveGlucoCustomParamter = async () => {
        const result = await dispatch(deletePatientGlucoCustomParameter(patientId))
        if (result?.message.toLowerCase() === "success") {
            await dispatch(resetPatientGlucoCustomParameter())
            addToast(GLUCO_DELETE_SUCCESS_MSG, {
                appearance: "success",
                autoDismiss: true
            })
            dispatch(getPatientInfo({ patientId }))
            setShowRemoveModal(false);
            setIsCustomParameter(false);
            setGlucoseCustomParameterTab(false);
            setIsEdit(false);
            setShowCustomParameter(false);
        } else {
            addToast(GLUCO_DELETE_ERR_MSG, {
                appearance: "error",
                autoDismiss: true
            })
            setShowRemoveModal(false);
        }
    }

    return (
        <div>
            <div className="flex flex-col gap-9 mb-4">
                <div className="flex justify-start items-center gap-2">
                    <h6 className="text-lg font-bold text-secondary mb-2">mg/dl</h6>
                </div>
                <div className="relative">
                    <Slider
                        range
                        min={49}
                        max={601}
                        onChange={(props) => {
                            let newProps = [...props];
                            newProps[0] = 49;
                            newProps[props.length - 1] = 601;
                            setGlucoValues(newProps);
                        }}
                        value={glucoValues}
                        marks={marksGlucoMeter}
                        tooltip={{ open: false }}
                        trackStyle={trackColors.map((color, index) => ({
                            backgroundColor: color
                        }))}
                    />
                    <Row className="flex flex-nowrap">
                        <Col className="flex justify-center items-center font-medium" style={{ width: `${glucoStageWidth.Hypoglycemia}%` }}>Hypoglycemia</Col>
                        <Col className="flex justify-center items-center font-medium flex-wrap" style={{ width: `${glucoStageWidth.Normal}%` }}>Normal</Col>
                        <Col className="flex justify-center items-center font-medium break-words flex-wrap" style={{ width: `${glucoStageWidth.Hyperglycemia}%` }}>Hyperglycemia</Col>
                    </Row>
                </div>
            </div>
            <div className="h-5 w-full mb-4 text-danger flex justify-start items-center gap-2 font-medium">
                {glucoCustomError !== "" &&
                    <>
                        <ExclamationCircleFilled className="text-[#E10505]" />
                        <span>{glucoCustomError}</span>
                    </>
                }
            </div>
            <Row className="flex justify-center items-center gap-4">
                {isEdit &&
                    <Button
                        type="default"
                        size="large"
                        className="w-full md:w-[40%] xl:w-1/3"
                        onClick={() => HandleShowRemoveModal()}
                    >
                        Remove Custom Parameters
                    </Button>
                }
                <Button
                    type="primary"
                    size="large"
                    disabled={disabledGluco || glucoCustomError}
                    className="w-full md:w-[40%] xl:w-1/3"
                    onClick={() => HandleShowSaveModal()}
                >
                    Save
                </Button>
            </Row>
            <Modal
                footer={true}
                open={showSaveModal}
                centered
                width={700}
                onCancel={() => setShowSaveModal(false)}
                title={<h4 className="text-xl">Are you sure you want to save the changes?</h4>}
            >
                <Row className="flex justify-end items-center gap-6 pr-4 mt-4">
                    <Button
                        type="default"
                        size="large"
                        className="w-32"
                        onClick={() => setShowSaveModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        className="w-32"
                        disabled={postOxiCustomParameterLoading || updateOxiCustomParameterLoading}
                        onClick={() => handleSubmit()}
                    >
                        Save
                    </Button>
                </Row>
            </Modal>
            <Modal
                footer={true}
                open={showRemoveModal}
                centered
                width={700}
                onCancel={() => setShowRemoveModal(false)}
                title={<h4 className="text-xl">Are you sure you want to remove custom parameters and go back to default?</h4>}
            >
                <Row className="flex justify-end items-center gap-6 pr-4 mt-4">
                    <Button
                        type="default"
                        size="large"
                        className="w-32"
                        onClick={() => setShowRemoveModal(false)}
                    >
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        size="large"
                        className="w-32"
                        disabled={deleteOxiCustomParameterLoading}
                        onClick={() => handleRemoveGlucoCustomParamter()}
                    >
                        Confirm
                    </Button>
                </Row>
            </Modal>
        </div>
    )
}

export default PatientGlucoCustomParameter