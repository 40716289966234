import {
  GET_RTM_PAIN_MANAGEMENT_READING_IDS_REQUEST,
  GET_RTM_PAIN_MANAGEMENT_READING_IDS_SUCCESS,
  GET_RTM_PAIN_MANAGEMENT_READING_IDS_FAIL,
  GET_RTM_PAIN_MANAGEMENT_SINGLE_READING_REQUEST,
  GET_RTM_PAIN_MANAGEMENT_SINGLE_READING_SUCCESS,
  GET_RTM_PAIN_MANAGEMENT_SINGLE_READING_FAIL,
  GET_RTM_MENTAL_HEALTH_READING_IDS_REQUEST,
  GET_RTM_MENTAL_HEALTH_READING_IDS_SUCCESS,
  GET_RTM_MENTAL_HEALTH_READING_IDS_FAIL,
  GET_RTM_MENTAL_HEALTH_SINGLE_READING_REQUEST,
  GET_RTM_MENTAL_HEALTH_SINGLE_READING_SUCCESS,
  GET_RTM_MENTAL_HEALTH_SINGLE_READING_FAIL,
  GET_RTM_ADDICTION_MEDICINE_READING_IDS_REQUEST,
  GET_RTM_ADDICTION_MEDICINE_READING_IDS_SUCCESS,
  GET_RTM_ADDICTION_MEDICINE_READING_IDS_FAIL,
  GET_RTM_ADDICTION_MEDICINE_SINGLE_READING_REQUEST,
  GET_RTM_ADDICTION_MEDICINE_SINGLE_READING_SUCCESS,
  GET_RTM_ADDICTION_MEDICINE_SINGLE_READING_FAIL
} from "../types/rtmConstants"

//For RTM Pain Management device
export const getRtmPainManagement = (state = {}, action) => {
  switch (action.type) {
    case GET_RTM_PAIN_MANAGEMENT_READING_IDS_REQUEST:
      return { loading: true }
    case GET_RTM_PAIN_MANAGEMENT_READING_IDS_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_RTM_PAIN_MANAGEMENT_READING_IDS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
//For single RTM Pain Management device
export const getSingleRtmPainManagement = (state = {}, action) => {
  switch (action.type) {
    case GET_RTM_PAIN_MANAGEMENT_SINGLE_READING_REQUEST:
      return { loading: true }
    case GET_RTM_PAIN_MANAGEMENT_SINGLE_READING_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_RTM_PAIN_MANAGEMENT_SINGLE_READING_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

//For RTM Mental Health device
export const getRtmMentalHealth = (state = {}, action) => {
  switch (action.type) {
    case GET_RTM_MENTAL_HEALTH_READING_IDS_REQUEST:
      return { loading: true }
    case GET_RTM_MENTAL_HEALTH_READING_IDS_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_RTM_MENTAL_HEALTH_READING_IDS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
//For single RTM Mental Health device
export const getSingleRtmMentalHealth = (state = {}, action) => {
  switch (action.type) {
    case GET_RTM_MENTAL_HEALTH_SINGLE_READING_REQUEST:
      return { loading: true }
    case GET_RTM_MENTAL_HEALTH_SINGLE_READING_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_RTM_MENTAL_HEALTH_SINGLE_READING_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}

//For RTM Addiction Medicine device
export const getRtmAddictionMedicine = (state = {}, action) => {
  switch (action.type) {
    case GET_RTM_ADDICTION_MEDICINE_READING_IDS_REQUEST:
      return { loading: true }
    case GET_RTM_ADDICTION_MEDICINE_READING_IDS_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_RTM_ADDICTION_MEDICINE_READING_IDS_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
//For single RTM Addiction Medicine device
export const getSingleRtmAddictionMedicine = (state = {}, action) => {
  switch (action.type) {
    case GET_RTM_ADDICTION_MEDICINE_SINGLE_READING_REQUEST:
      return { loading: true }
    case GET_RTM_ADDICTION_MEDICINE_SINGLE_READING_SUCCESS:
      return { loading: false, data: action.payload }
    case GET_RTM_ADDICTION_MEDICINE_SINGLE_READING_FAIL:
      return { loading: false, error: action.payload }
    default:
      return state
  }
}
