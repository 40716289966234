import React from "react"
import { NavLink } from "react-router-dom"

import { Input, Button } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { userLoginAction, clearUserLoginError } from "../../redux/actions/userActions"

import * as yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import FloatInput from "../../utils/FloatInput/FloatInput"
import { TbDeviceLandlinePhone } from "react-icons/tb"
import { MdLan } from "react-icons/md"
import { LockFilled } from "@ant-design/icons"

const landlineSchema = yup
  .object({
    password: yup.string().required("Enter Valid Password"),
    landline: yup.string().min(10).max(10).required("Enter 10 digit valid landline number"),
    extension: yup.string().min(1).max(5).required("Enter valid extension")
  })
  .required()

const landlineDefaultValues = {
  landline: "",
  password: "",
  extension: ""
}

const LoginWithLandline = ({ setShowLandline, disableLogin, setDisableLogin }) => {
  const dispatch = useDispatch()

  const { loading, error } = useSelector(state => state.userInfo)

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    landlineDefaultValues,
    resolver: yupResolver(landlineSchema)
  })

  const landlineWatcher = watch("landline")
  const extensionWatcher = watch("extension")
  const passwordWatcher = watch("password")

  const onSubmit = async ({ landline, password, extension }) => {
    await dispatch(userLoginAction(landline, password, extension))
  }

  return (
    <form className="mt-4" onSubmit={handleSubmit(onSubmit)}>
      <div className="min-h-[65px] d-flex justify-content-between">
        <div className="col-md-7 pr-2">
          <Controller
            name="landline"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FloatInput label="Landline" placeholder="Landline Number">
                <Input
                  type="number"
                  size="large"
                  prefix={
                    <TbDeviceLandlinePhone
                      style={{
                        fill: "#666B78",
                        color: "#484C56",
                        fillOpacity: "70%"
                      }}
                      size={15}
                    />
                  }
                  status={errors.landline ? "error" : undefined}
                  {...field}
                  disabled={disableLogin}
                />
              </FloatInput>
            )}
          />
          {errors.landline ? (
            <small className="text-danger">{errors.landline.message}</small>
          ) : null}
        </div>
        <div className="col-md-5">
          <Controller
            name="extension"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <FloatInput label="Extension">
                <Input
                  size="large"
                  type="number"
                  prefix={<MdLan className="text-[#666B78]" size={15} />}
                  status={errors.extension ? "error" : undefined}
                  {...field}
                  disabled={disableLogin}
                />
              </FloatInput>
            )}
          />
          {errors.extension ? (
            <small className="text-danger">{errors.extension.message}</small>
          ) : null}
        </div>
      </div>

      <p
        className="mt-1 mb-4 text-center text-secondary"
        style={{ cursor: "pointer" }}
        onClick={() => {
          setShowLandline(false)
          dispatch(clearUserLoginError())
          setDisableLogin(false)
        }}>
        Login with email or mobile
      </p>

      <div className="mb-1 min-h-[65px]">
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FloatInput label="Password">
              <Input.Password
                size="large"
                prefix={<LockFilled className="text-[#666B78]" size={15} />}
                status={errors.password ? "error" : undefined}
                {...field}
                disabled={disableLogin}
              />
            </FloatInput>
          )}
        />
        {errors.password ? <small className="text-danger">{errors.password.message}</small> : null}
      </div>

      <NavLink to="/forgot-password" className="mb-3 text-primary float-end px-0 no-underline">
        Forgot Password?
      </NavLink>

      <Button
        block
        size="large"
        type="primary"
        htmlType="submit"
        disabled={
          Boolean(loading) ||
          disableLogin ||
          !passwordWatcher?.length ||
          !landlineWatcher?.length ||
          !extensionWatcher?.length
        }>
        {loading ? "Loading..." : "Login"}
      </Button>

      {error !== undefined && typeof error === "string" && (
        <p className="mt-3 text-danger text-center font-medium">{error}</p>
      )}
    </form>
  )
}

export default LoginWithLandline
