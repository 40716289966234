import { Col, Row, Slider } from "antd"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getPatientGlucoCustomParameter, getPatientOxiCustomParameter } from "../../redux/actions/customParameterActions"

const PatientGlucoseMeterProgressbar = ({ patientId, glucoData, setGlucoseCustomParameterTab, setIsCustomParameter }) => {
    const [glucoValues, setGlucoValues] = useState([49, 60, 140, 601])
    const [marksGlucoMeter, setMarksGlucoMeter] = useState({})
    const [glucoValuePercent, setGlucoValuePercent] = useState(0);

    const [glucoStageWidth, setGlucoStageWidth] = useState({
        Hypoglycemia: 20,
        Normal: 20,
        Hyperglycemia: 20
    });

    const dispatch = useDispatch();

    const { loading: GlucoCustomParameterLoading, glucoCustomParameters } = useSelector(state => state.getPatientGlucoCustomParameter)

    useEffect(() => {
        if (!GlucoCustomParameterLoading) {
            if (glucoCustomParameters?.hasOwnProperty("normalLower")) {
                setGlucoseCustomParameterTab(true)
                setIsCustomParameter(true)
                let customGlucoValueArr = [];
                const glucoValuesOrder = ["lowerLimit", "normalLower", "normalHigher", "higherLimit"]
                for (let i = 0; i < glucoValuesOrder.length; i++) {
                    customGlucoValueArr.push(glucoCustomParameters && glucoCustomParameters[glucoValuesOrder[i]])
                }
                setGlucoValues(customGlucoValueArr);
            } else {
                setGlucoseCustomParameterTab(false)
                setGlucoValues([49, 60, 140, 601])
            }
        }
    }, [glucoCustomParameters])

    function calculatePercentage(start, end, minValue, maxValue) {
        const range = maxValue - minValue;
        const percentage = ((end - start) / range) * 100;
        return parseInt(percentage)
    }

    useEffect(() => {
        if (!GlucoCustomParameterLoading) {
            dispatch(getPatientGlucoCustomParameter(patientId))
        }
    }, [])

    useEffect(() => {
        let firstToSecondPoint = calculatePercentage(glucoValues[0], glucoValues[1], glucoValues[0], glucoValues[glucoValues.length - 1]);
        let secondToThirdPoint = calculatePercentage(glucoValues[1], glucoValues[2], glucoValues[0], glucoValues[glucoValues.length - 1]);
        let thirdToFourthPoint = calculatePercentage(glucoValues[2], glucoValues[3], glucoValues[0], glucoValues[glucoValues.length - 1]);
        firstToSecondPoint = (firstToSecondPoint < 10) ? 10 : firstToSecondPoint
        secondToThirdPoint = (secondToThirdPoint > 40) ? 40 : secondToThirdPoint
        thirdToFourthPoint = (thirdToFourthPoint > 50) ? 50 : thirdToFourthPoint

        setGlucoStageWidth({
            ...glucoStageWidth,
            Hypoglycemia: firstToSecondPoint,
            Normal: secondToThirdPoint,
            Hyperglycemia: thirdToFourthPoint,
        })
    }, [glucoValues])

    useEffect(() => {
        const newGlucovalue = glucoValues.slice(1, -1)
        const newMarksGluco = newGlucovalue.reduce((acc, value, index) => {
            acc[value] = {
                label: (<>{value}</>),
                style: {
                    position: "absolute",
                    top: "-50px",
                    fontSize: "16px"
                }
            };
            return acc;
        }, {});
        setMarksGlucoMeter(newMarksGluco)
        const glucoValPercent = calculatePercentage(glucoValues[0], glucoData?.[0]?.glucoseLevel, glucoValues[0], glucoValues[glucoValues.length - 1])
        setGlucoValuePercent(glucoValPercent < 0 ? 0 : glucoValPercent);
    }, [glucoValues])

    const trackColors = ["#ff3b30", "#34c759", "#ff3b30"]

    return (
        <div>
            <div className="flex flex-col gap-4 mb-5">
                <div className="w-min border-[1px] border-current rounded py-4 text-center">
                    <h6 className="w-28 text-lg font-bold text-secondary mb-2">mg/dl</h6>
                    <p className="font-bold text-4xl mb-0">
                        {glucoData?.[0]?.glucoseLevel}
                    </p>
                </div>
                <div className="relative">
                    <Slider
                        range
                        min={49}
                        max={601}
                        draggableTrack={false}
                        value={glucoValues}
                        marks={marksGlucoMeter}
                        handleStyle={{ display: "none" }}
                        step={null}
                        dots={true}
                        dotStyle={{ borderColor: 'transparent', backgroundColor: 'transparent', width: 0, height: 0 }} // Hide dots
                        trackStyle={trackColors.map((color, index) => ({
                            backgroundColor: color
                        }))}
                    />
                    <div className="absolute top-[12px] rounded-full bg-[#0c66e4] cursor-pointer" style={{ width: "14px", height: "14px", left: `${glucoValuePercent}%` }}></div>
                    <Row className="w-full flex flex-nowrap">
                        <Col className="flex justify-center items-center font-medium" style={{ width: `${glucoStageWidth.Hypoglycemia}%` }}>Hypoglycemia</Col>
                        <Col className="flex justify-center items-center font-medium" style={{ width: `${glucoStageWidth.Normal}%` }}>Normal</Col>
                        <Col className="flex justify-center items-center font-medium break-words" style={{ width: `${glucoStageWidth.Hyperglycemia}%` }}>Hyperglycemia</Col>
                    </Row>
                </div>
            </div>
        </div>
    )
}

export default PatientGlucoseMeterProgressbar