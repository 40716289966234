import React from 'react'
import { useNavigate } from 'react-router-dom'
import {RxLinkBreak1} from 'react-icons/rx'

const HandleApiFail = ({message,route}) => {
    const navigate = useNavigate()

  return (
    <div className="text-center my-5">
        <div className='d-flex justify-content-center'>
        <h1 className='text-center'><RxLinkBreak1 size={50} className='text-secondary'/></h1>
        </div>
    {/* <h3 className="text-danger fw-light">{message}</h3> */}
    <h3 className="text-danger fw-light mb-2 text-xl">Oops! Something Went Wrong.</h3>
    <h4 className="text-secondary fw-light mb-2 text-lg">Please Try Later</h4>
    <button className='btn btn-dark shadow btn-sm px-3' onClick={()=>navigate(route)}>Back</button>
    </div>
  )
}

export default HandleApiFail