export const GET_PATIENT_BP_CUSTOM_PARAMETER_REQUEST = "GET_PATIENT_BP_CUSTOM_PARAMETER_REQUEST"
export const GET_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS = "GET_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS"
export const GET_PATIENT_BP_CUSTOM_PARAMETER_FAIL = "GET_PATIENT_BP_CUSTOM_PARAMETER_FAIL"
export const RESET_PATIENT_BP_CUSTOM_PARAMETER = "RESET_PATIENT_BP_CUSTOM_PARAMETER"

export const POST_PATIENT_BP_CUSTOM_PARAMETER_REQUEST = "POST_PATIENT_BP_CUSTOM_PARAMETER_REQUEST"
export const POST_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS = "POST_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS"
export const POST_PATIENT_BP_CUSTOM_PARAMETER_FAIL = "POST_PATIENT_BP_CUSTOM_PARAMETER_FAIL"

export const DELETE_PATIENT_BP_CUSTOM_PARAMETER_REQUEST = "DELETE_PATIENT_BP_CUSTOM_PARAMETER_REQUEST"
export const DELETE_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS = "DELETE_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS"
export const DELETE_PATIENT_BP_CUSTOM_PARAMETER_FAIL = "DELETE_PATIENT_BP_CUSTOM_PARAMETER_FAIL"

export const UPDATE_PATIENT_BP_CUSTOM_PARAMETER_REQUEST = "UPDATE_PATIENT_BP_CUSTOM_PARAMETER_REQUEST"
export const UPDATE_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS = "UPDATE_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS"
export const UPDATE_PATIENT_BP_CUSTOM_PARAMETER_FAIL = "UPDATE_PATIENT_BP_CUSTOM_PARAMETER_FAIL"

export const GET_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST = "GET_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST"
export const GET_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS = "GET_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS"
export const GET_PATIENT_OXI_CUSTOM_PARAMETER_FAIL = "GET_PATIENT_OXI_CUSTOM_PARAMETER_FAIL"
export const RESET_PATIENT_OXI_CUSTOM_PARAMETER = "RESET_PATIENT_OXI_CUSTOM_PARAMETER"

export const POST_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST = "POST_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST"
export const POST_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS = "POST_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS"
export const POST_PATIENT_OXI_CUSTOM_PARAMETER_FAIL = "POST_PATIENT_OXI_CUSTOM_PARAMETER_FAIL"

export const DELETE_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST = "DELETE_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST"
export const DELETE_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS = "DELETE_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS"
export const DELETE_PATIENT_OXI_CUSTOM_PARAMETER_FAIL = "DELETE_PATIENT_OXI_CUSTOM_PARAMETER_FAIL"

export const UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST = "UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST"
export const UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS = "UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS"
export const UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_FAIL = "UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_FAIL"

export const GET_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST = "GET_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST"
export const GET_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS = "GET_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS"
export const GET_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL = "GET_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL"
export const RESET_PATIENT_GLUCO_CUSTOM_PARAMETER = "RESET_PATIENT_GLUCO_CUSTOM_PARAMETER"

export const POST_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST = "POST_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST"
export const POST_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS = "POST_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS"
export const POST_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL = "POST_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL"

export const DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST = "DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST"
export const DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS = "DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS"
export const DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL = "DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL"

export const UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST = "UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST"
export const UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS = "UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS"
export const UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL = "UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL"