import React, { useEffect, useState } from "react"

// import moment from "moment"
import { Button, Checkbox, Input, Space, Tag, Tooltip } from "antd"
import PatientManualLogModal from "../Patient/PatientManualLogModal"
import ReactDragListView from "react-drag-listview"
import ProTable from "@ant-design/pro-table"
import { DownOutlined } from "@ant-design/icons"
import PatientStatusFilterDropdown from "../Patient/EligiblePatient/PatientStatusFilterDropdown"
import EquipmentStatusDropdown from "../Patient/EligiblePatient/EquipmentStatusDropdown"

const ProviderPatientTable = ({ sortedData, loading }) => {
  const [data, setData] = useState([])
  // const [currentDate] = useState(new Date())
  // const [priorDate, setPriorDate] = useState() //eslint-disable-line
  const [manualLogOpen, setManualLogOpen] = useState(false)
  const [patientDetails, setPatientDetails] = useState(null)
  const [manualCountType, setManualCountType] = useState()
  const [selectedRowKeys, setSelectedRowKeys] = useState([])
  const [filteredPatients, setFilteredPatients] = useState([])
  const [equipmentStatus, setEquipmentStatus] = useState()
  const [patientStatus, setPatientStatus] = useState()

  // const handleViewReportModalShow = (getPatientId) => {
  //   setCurrentPatientId(getPatientId);
  //   setViewReportModalShow(true);
  // };

  // useEffect(() => {
  //   if (currentDate !== undefined) {
  //     const manageDate = moment(currentDate).subtract(40, "days")
  //     setPriorDate(manageDate._d) //eslint-disable-line
  //   }
  // }, [currentDate])

  const onPatientStatusSelection = (status) => {
    setPatientStatus(status)
  }

  const onPatientStatusSelectionClear = () => {
    setPatientStatus("")
  }

  const onEquipStatusSelection = (status) => {
    setEquipmentStatus(status)
  }

  const onEquipStatusSelectionClear = () => {
    setEquipmentStatus("")
  }

  const handleShowManualLogClick = (obj, programType) => {
    setManualLogOpen(true)
    setPatientDetails(obj)
    setManualCountType(programType)
  }

  const handleSelectAll = (selected) => {
    // @ts-expect-error
    setSelectedRowKeys(selected ? data?.map(r => r.id) : [])
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
    columnTitle: (
      <Checkbox
        checked={selectedRowKeys?.length === data?.length}
        indeterminate={
          selectedRowKeys?.length > 0 && selectedRowKeys.length < data?.length
        }
        onChange={e => handleSelectAll(e.target.checked)}
      />
    )
  }

  // for table
  // column for table
  const [columns, setColumns] = useState([
    {
      title: <span className="dragHandler cursor-move">S.No.</span>,
      dataIndex: "sNo",
      key: "sNo"
    },
    {
      disable: true,
      title: <span className="dragHandler cursor-move">Full Name</span>,
      dataIndex: "name",
      key: "name",
      render: (name, { id, status }) => (
        <p
          className={`${status ? "text-danger" : ""} text-capitalize wrap-text mb-0`}
          onClick={() => (window.location.href = `/patient/${id}`)}
          style={{ cursor: "pointer" }}>
          {name}
        </p>
      ),
      sorter: (a, b) => a?.name?.localeCompare(b?.name)
    },
    {
      disable: true,
      title: <span className="dragHandler cursor-move">Username</span>,
      dataIndex: "username",
      key: "username",
      render: username => <p className="wrap-text mb-0">{username}</p>
    },
    {
      title: <span className="dragHandler cursor-move">User Type</span>,
      dataIndex: "userType",
      key: "userType",
      render: userType => <p className="wrap-text mb-0">{userType}</p>
    },
    {
      title: <span className="dragHandler cursor-move">Total Reading</span>,
      dataIndex: "totalReading",
      key: "totalReading",
      render: totalReading => <p className="wrap-text mb-0">{totalReading}</p>,
      sorter: (a, b) => a.totalReading - b.totalReading
    },
    {
      title: <span className="dragHandler cursor-move">Patient Status</span>,
      dataIndex: "patientStatus",
      key: "patientStatus",
      sorter: (a, b) => (a.patientStatus ?? "").localeCompare(b.patientStatus ?? ""),
      render: (patientStatus) => (
        <span className="capitalize">
          {patientStatus}
        </span>
      )
    },
    {
      title: <span className="dragHandler cursor-move">Equipment Status</span>,
      dataIndex: "equipmentStatus",
      key: "equipmentStatus",
      sorter: (a, b) => (a.equipmentStatus ?? "").localeCompare(b.equipmentStatus ?? ""),
      render: (equipmentStatus) => (
        <span className="capitalize">
          {equipmentStatus}
        </span>
      )
    },
    {
      title: <span className="dragHandler cursor-move">RTM Pain Management Count</span>,
      dataIndex: "rtmPainManagementCount",
      key: "rtmPainManagementCount",
      render: rtmPainManagementCount => <p className="wrap-text mb-0">{rtmPainManagementCount}</p>,
      sorter: (a, b) => a.rtmPainManagementCount - b.rtmPainManagementCount
    },
    {
      title: <span className="dragHandler cursor-move">RTM Medicated Assisted Therapy Count</span>,
      dataIndex: "rtmMedicatedAssistedTherapyCount",
      key: "rtmMedicatedAssistedTherapyCount",
      render: rtmMedicatedAssistedTherapyCount => (
        <p className="wrap-text mb-0">{rtmMedicatedAssistedTherapyCount}</p>
      ),
      sorter: (a, b) => a.rtmMedicatedAssistedTherapyCount - b.rtmMedicatedAssistedTherapyCount
    },
    {
      title: <span className="dragHandler cursor-move">Unit No.</span>,
      dataIndex: "unit",
      key: "unit",
      render: unit => <p className="wrap-text mb-0">{unit}</p>,
      sorter: (a, b) => a?.unit - b?.unit
    },
    {
      title: <span className="dragHandler cursor-move">Room No.</span>,
      dataIndex: "room",
      key: "room",
      render: room => <p className="wrap-text mb-0">{room}</p>,
      sorter: (a, b) => Number(a?.room) - Number(b?.room)
    },
    {
      title: <span className="dragHandler cursor-move">Critical Status</span>,
      dataIndex: "status",
      key: "status",
      render: status =>
        status !== null && status === true ? <Tag color="red">Critical</Tag> : <span>N/A</span>,
      sorter: a => (a?.status !== null && a?.status === true ? 0 - 1 : 1 - 0)
    },
    {
      title: <span className="dragHandler cursor-move">Total RPM Mins Spent</span>,
      key: "rpmManualLogCount",
      dataIndex: "rpmManualLogCount",
      sorter: (a, b) => a.rpmManualLogCount - b.rpmManualLogCount,
      render: (rpmManualLogCount, row) => (
        <Button onClick={() => handleShowManualLogClick(row, "rpm")}>
          {rpmManualLogCount} Mins
        </Button>
      )
    },
    {
      title: <span className="dragHandler cursor-move">Total CCM Mins Spent</span>,
      key: "ccmManualLogCount",
      dataIndex: "ccmManualLogCount",
      sorter: (a, b) => a.ccmManualLogCount - b.ccmManualLogCount,
      render: (ccmManualLogCount, row) => (
        <Button onClick={() => handleShowManualLogClick(row, "ccm")}>
          {ccmManualLogCount} Mins
        </Button>
      )
    },
    {
      title: <span className="dragHandler cursor-move">Total Mins Spent</span>,
      dataIndex: "manualLogCount",
      key: "manualLogCount",
      render: (manualLogCount, row) => (
        <Button onClick={() => handleShowManualLogClick(row)}>{manualLogCount} Mins</Button>
      ),
      sorter: (a, b) => a.manualLogCount - b.manualLogCount
    }
  ])

  useEffect(() => {
    const newData = []
    let sno = 1
    sortedData?.forEach(data => {
      const {
        id,
        lastName,
        userName,
        firstName,
        middleName,
        userType,
        // rpmReadingCount,
        monthlyLastestTotalReadingRPM,
        manualLogCount,
        rpmManualLogCount,
        ccmManualLogCount,
        // manualTime,
        // addressDetails,
        rtmPainManagementCount,
        rtmMedicatedAssistedTherapyCount,
        unit,
        room,
        criticalStatus,
        patientStatus,
        equipmentStatus
      } = data
      newData.push({
        key: sno,
        sNo: sno,
        name:
          !middleName || middleName === null
            ? `${firstName} ${lastName}`
            : `${firstName} ${middleName} ${lastName}`,
        username: userName,
        id,
        userType: userType,
        // totalReading: rpmReadingCount ? rpmReadingCount : 0,
        totalReading: monthlyLastestTotalReadingRPM,
        manualLogCount: manualLogCount ? manualLogCount : 0,
        rpmManualLogCount,
        ccmManualLogCount,
        rtmPainManagementCount,
        rtmMedicatedAssistedTherapyCount,
        unit: unit ? unit : "N/A",
        room: room ? room : "N/A",
        status: criticalStatus,
        patientStatus,
        equipmentStatus
      })

      sno += 1
    })
    setData(newData)
  }, [sortedData])

  useEffect(() => {
    setTimeout(() => {
      if (columns && columns.length && localStorage.getItem("patientCols")) {
        const storedCols = JSON.parse(localStorage.getItem("patientCols"))
        const defaultCols = [...columns]
        const storedColsIndex = storedCols.map((col, index) => col.key)
        const sorted = defaultCols.sort(function (a, b) {
          return storedColsIndex.indexOf(a.key) - storedColsIndex.indexOf(b.key)
        })
        Promise.all(sorted).then(() => {
          setColumns([...defaultCols])
        })
      }
    }, 1000)
  }, [])

  useEffect(() => {
    let filtered = data

    if (equipmentStatus) {
      filtered = filtered.filter(
        (patient) => patient.equipmentStatus?.toLowerCase()?.trim() === equipmentStatus?.toLowerCase()?.trim()
      )
    }
    if (patientStatus) {
      filtered = filtered.filter(
        (patient) => patient.patientStatus?.toLowerCase()?.trim() === patientStatus?.toLowerCase()?.trim()
      )
    }
    setFilteredPatients(filtered)
  }, [ equipmentStatus, patientStatus, data])

  const onDragEnd = (fromIndex, toIndex) => {
    setColumns(prev => {
      const nextColumns = [...prev]
      const item = nextColumns.splice(fromIndex, 1)[0]
      nextColumns.splice(toIndex, 0, item)
      localStorage.setItem(
        "patientCols",
        JSON.stringify(nextColumns.map(col => ({ key: col.key })))
      )
      return nextColumns
    })
  }

  return (
    <>
    <ReactDragListView.DragColumn
        onDragEnd={onDragEnd}
        nodeSelector="th"
        handleSelector=".dragHandler"
        ignoreSelector="react-resizable-handle">
        <ProTable loading={loading} columns={columns} dataSource={filteredPatients} rowKey={record => record.id}
        tableAlertOptionRender={({ onCleanSelected }) => (
          <div className="flex max-sm:flex-col">                
            <Button onClick={onCleanSelected} className="mr-auto text-danger border-danger">
              Clear selection
            </Button>
          </div>
        )}
        rowSelection={
          rowSelection
        }
        options={{
          density: false,
          reload: false,
          fullScreen: false,
          setting: {
            children: (
              <Space.Compact
                className={`card light flex flex-col xl:flex-row mb-[105px] xl:mb-0 gap-y-[8px]`}
                style={{ borderWidth: 0, height: 48 }}>
                <Button
                  style={{ width: 241, height: "inherit" }}
                  className="flex place-items-center p-0">
                  <span className="secondary text-xs px-4">Filter By</span>
                  <div
                    className="border-l flex-1 flex items-center justify-center gap-2"
                    style={{
                      height: "inherit",
                      borderColor: "rgba(0, 0, 0, 0.1)"
                    }}>
                    Column Visibility
                    <DownOutlined />
                  </div>
                </Button>
                <Space.Compact
                 style={{ height: "inherit", display: "flex" }}
                 onClick={e => e.stopPropagation()}>
                 <EquipmentStatusDropdown
                   onEquipStatusSelection={onEquipStatusSelection}
                   onEquipStatusSelectionClear={onEquipStatusSelectionClear}
                 />
                </Space.Compact>
                <Space.Compact
                  style={{ height: "inherit", display: "flex" }}
                  onClick={e => e.stopPropagation()}>
                    <PatientStatusFilterDropdown
                      onPatientStatusSelection={onPatientStatusSelection}
                      onPatientStatusSelectionClear={onPatientStatusSelectionClear}
                    />
                </Space.Compact>
              </Space.Compact>
            )
          }
        }}
        search={false}
        pagination={{
          defaultPageSize: 10,
          showTotal: (total, range) => `Showing ${range[0]}-${range[1]} of ${total} items`
        }}
        scroll={{ x: "max-content" }}  />
      </ReactDragListView.DragColumn>
      <PatientManualLogModal
        manualLogOpen={manualLogOpen}
        patientDetails={patientDetails}
        setManualLogOpen={setManualLogOpen}
        manualCountType={manualCountType}
      />
    </>
  )
}

export default ProviderPatientTable
