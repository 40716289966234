import {
    GET_PATIENT_BP_CUSTOM_PARAMETER_REQUEST,
    GET_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS,
    GET_PATIENT_BP_CUSTOM_PARAMETER_FAIL,
    POST_PATIENT_BP_CUSTOM_PARAMETER_REQUEST,
    POST_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS,
    POST_PATIENT_BP_CUSTOM_PARAMETER_FAIL,
    UPDATE_PATIENT_BP_CUSTOM_PARAMETER_REQUEST,
    UPDATE_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS,
    UPDATE_PATIENT_BP_CUSTOM_PARAMETER_FAIL,
    DELETE_PATIENT_BP_CUSTOM_PARAMETER_REQUEST,
    DELETE_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS,
    DELETE_PATIENT_BP_CUSTOM_PARAMETER_FAIL,
    GET_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST,
    GET_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS,
    GET_PATIENT_OXI_CUSTOM_PARAMETER_FAIL,
    POST_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST,
    POST_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS,
    POST_PATIENT_OXI_CUSTOM_PARAMETER_FAIL,
    DELETE_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST,
    DELETE_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS,
    DELETE_PATIENT_OXI_CUSTOM_PARAMETER_FAIL,
    UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST,
    UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS,
    UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_FAIL,
    RESET_PATIENT_BP_CUSTOM_PARAMETER,
    RESET_PATIENT_OXI_CUSTOM_PARAMETER,
    GET_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST,
    GET_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS,
    GET_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL,
    RESET_PATIENT_GLUCO_CUSTOM_PARAMETER,
    POST_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST,
    POST_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS,
    POST_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL,
    UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST,
    UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS,
    UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL,
    DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST,
    DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS,
    DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL
} from "../types/customParameterConstant";

export const getPatientBpCustomParameterReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PATIENT_BP_CUSTOM_PARAMETER_REQUEST:
            return { loading: true }
        case GET_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS:
            return { loading: false, bpCustomParameters: action.payload }
        case GET_PATIENT_BP_CUSTOM_PARAMETER_FAIL:
            return { loading: false, error: action.payload }
        case RESET_PATIENT_BP_CUSTOM_PARAMETER:
            return { loading: false, bpCustomParameters: {} }
        default:
            return state
    }
}

export const postPatientBpCustomParameterReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_PATIENT_BP_CUSTOM_PARAMETER_REQUEST:
            return { loading: true }
        case POST_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS:
            return { loading: false, postBpCustomParameter: action.payload }
        case POST_PATIENT_BP_CUSTOM_PARAMETER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const updatePatientBpCustomParameterReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_PATIENT_BP_CUSTOM_PARAMETER_REQUEST:
            return { loading: true }
        case UPDATE_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS:
            return { loading: false, updateBpCustomParameter: action.payload }
        case UPDATE_PATIENT_BP_CUSTOM_PARAMETER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const deletePatientBpCustomParameterReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_PATIENT_BP_CUSTOM_PARAMETER_REQUEST:
            return { loading: true }
        case DELETE_PATIENT_BP_CUSTOM_PARAMETER_SUCCESS:
            return { loading: false, deleteBpCustomParameter: action.payload }
        case DELETE_PATIENT_BP_CUSTOM_PARAMETER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
// oximeter
export const getPatientOxiCustomParameterReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST:
            return { loading: true }
        case GET_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS:
            return { loading: false, oxiCustomParameters: action.payload }
        case GET_PATIENT_OXI_CUSTOM_PARAMETER_FAIL:
            return { loading: false, error: action.payload }
        case RESET_PATIENT_OXI_CUSTOM_PARAMETER:
            return { loading: false, oxiCustomParameters: {} }
        default:
            return state
    }
}

export const postPatientOxiCustomParameterReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST:
            return { loading: true }
        case POST_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS:
            return { loading: false, postOxiCustomParameter: action.payload }
        case POST_PATIENT_OXI_CUSTOM_PARAMETER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const updatePatientOxiCustomParameterReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST:
            return { loading: true }
        case UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS:
            return { loading: false, updateOxiCustomParameter: action.payload }
        case UPDATE_PATIENT_OXI_CUSTOM_PARAMETER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const deletePatientOxiCustomParameterReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_PATIENT_OXI_CUSTOM_PARAMETER_REQUEST:
            return { loading: true }
        case DELETE_PATIENT_OXI_CUSTOM_PARAMETER_SUCCESS:
            return { loading: false, deleteOxiCustomParameter: action.payload }
        case DELETE_PATIENT_OXI_CUSTOM_PARAMETER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

// GLUCOSE METER
export const getPatientGlucoCustomParameterReducer = (state = {}, action) => {
    switch (action.type) {
        case GET_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST:
            return { loading: true }
        case GET_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS:
            return { loading: false, glucoCustomParameters: action.payload }
        case GET_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL:
            return { loading: false, error: action.payload }
        case RESET_PATIENT_GLUCO_CUSTOM_PARAMETER:
            return { loading: false, bpCustomParameters: {} }
        default:
            return state
    }
}

export const postPatientGlucoCustomParameterReducer = (state = {}, action) => {
    switch (action.type) {
        case POST_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST:
            return { loading: true }
        case POST_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS:
            return { loading: false, postGlucoCustomParameter: action.payload }
        case POST_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const updatePatientGlucoCustomParameterReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST:
            return { loading: true }
        case UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS:
            return { loading: false, updateGlucoCustomParameter: action.payload }
        case UPDATE_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}

export const deletePatientGlucoCustomParameterReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_REQUEST:
            return { loading: true }
        case DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_SUCCESS:
            return { loading: false, deleteGlucoCustomParameter: action.payload }
        case DELETE_PATIENT_GLUCO_CUSTOM_PARAMETER_FAIL:
            return { loading: false, error: action.payload }
        default:
            return state
    }
}
