import React, { useState, useEffect } from "react"

import { Layout, Grid, ConfigProvider } from "antd"
import localeLang from "antd/es/locale/en_US"

import _ from "lodash"
// import { useToasts } from "react-toast-notifications"

// import { useSelector } from "react-redux"

import Navbar from "./Navbar"
import Sidebar from "./Sidebar"

import "./main-layout.css"

const { useBreakpoint } = Grid
const { Content, Footer } = Layout
// const { defaultAlgorithm, darkAlgorithm } = theme

const MainLayout = ({ children }) => {
  const [sidebarHidden, setSidebarHidden] = useState(false)
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false)

  const screens = useBreakpoint()
  const isMobile = !screens?.lg

  // const { addToast } = useToasts()

  // const { isDark } = useSelector(state => state.themeConfig)

  useEffect(() => {
    if (isMobile) {
      setSidebarHidden(true)
    } else {
      setSidebarHidden(false)
      setSidebarCollapsed(false)
    }
  }, [isMobile])

  return (
    <ConfigProvider
      locale={localeLang}
      theme={{
        token: {
          colorPrimary: "#d7a66d"
        }
        // algorithm: isDark ? darkAlgorithm : defaultAlgorithm
      }}>
      <Layout className="main-layout">
        <Sidebar
          sidebarHidden={sidebarHidden}
          sidebarCollapsed={sidebarCollapsed}
          setSidebarHidden={setSidebarHidden}
          setSidebarCollapsed={setSidebarCollapsed}
        />
        <Layout>
          <Navbar
            sidebarHidden={sidebarHidden}
            setSidebarHidden={setSidebarHidden}
            sidebarCollapsed={sidebarCollapsed}
            setSidebarCollapsed={setSidebarCollapsed}
          />
          <Content>{children}</Content>
          <Footer style={{ textAlign: "center" }}>
            <h6> &#169; {new Date().getFullYear()}, FitPeo inc. All Rights Reserved</h6>
          </Footer>
        </Layout>
      </Layout>
    </ConfigProvider>
  )
}

export default MainLayout
