import { useEffect } from "react"

import { Card, Row, Col } from "antd"

import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { getProviderNPIAction } from "../../../redux/actions/providerAction"
import ProviderAddress from "./ProviderAddress"
import  formatPhoneNumber  from "../../../utils/formatPhoneNumber"
import ProfileAvatar from "../../../utils/ProfileAvatar"

const ProviderDetailHeader = ({ providerId }) => {
  const dispatch = useDispatch()
  const speciality = useLocation()?.state?.speciality // this is coming from its parent (ProviderList)

  const { singleProvider } = useSelector(state => state.singleProvider)
  const { data: providerNPI } = useSelector(state => state.getProviderNPI)

  useEffect(() => {
    if (singleProvider && providerId) {
      dispatch(getProviderNPIAction(providerId))
    }
  }, [singleProvider, providerId])

  return (
    <>
      <Row gutter={[16, 16]} className="mb-4">
        <Col lg={16} md={24} xs={24}>
          <Card className="shadow-sm h-full" loading={singleProvider === undefined}>
            <h4 className="text-2xl">Provider Details</h4>

            <div className="mt-4 flex gap-16 flex-wrap ">
              <div className="flex gap-3 items-center flex-1">
                <ProfileAvatar user = {singleProvider} />
                <div>
                  <h5 className="mb-1 text-xl capitalize">
                    {singleProvider?.firstName?.[0]?.toUpperCase() +
                      singleProvider?.firstName.slice(1)}{" "}
                    {singleProvider?.middleName} {singleProvider?.lastName}
                  </h5>
                  {singleProvider?.email ? (
                    <p className="text-sm text-secondary">{singleProvider.email}</p>
                  ) : null}

                  {singleProvider?.mobileNumber ? (
                    <p className="text-sm text-secondary">
                      {formatPhoneNumber(singleProvider?.mobileNumber)}
                    </p>
                  ) : null}
                </div>
              </div>
              <div>
                {speciality ? (
                  <div className="flex mb-2 capitalize">
                    <span className="text-secondary">Specializations :</span>
                    {speciality?.split("~")?.map((item, index) => (
                      <div className="ml-2 font-medium" key={index}>
                        <span>{index + 1}. </span>
                        <span>{item}</span>
                      </div>
                    ))}
                  </div>
                ) : null}
                {providerNPI?.npi ? (
                  <div className="mb-2 capitalize">
                    <span className="text-secondary">NPI :</span>
                    <span className="ml-2 font-medium">{providerNPI.npi}</span>
                  </div>
                ) : null}
              </div>
            </div>
          </Card>
        </Col>
        <Col lg={8} md={24} xs={24}>
          <ProviderAddress providerId={providerId} />
        </Col>
      </Row>
    </>
  )
}

export default ProviderDetailHeader
