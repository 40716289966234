/* eslint-disable @typescript-eslint/prefer-nullish-coalescing */
import React, { useState } from "react"
import { Button, Divider, Dropdown, Radio, Space, Typography, theme } from "antd"
import { CloseOutlined, DownOutlined } from "@ant-design/icons"
import { EquipmentStatus } from "./Types"

const { useToken } = theme

const EquipmentStatusDropdown = (props) => {
  const { onEquipStatusSelection, onEquipStatusSelectionClear } = props
  const { token } = useToken()

  const [open, setOpen] = useState(false)
  const [values, setValues] = useState()
  const [selectedEquipmentStatus, setSelectedEquipmentStatus] = useState()

  const contentStyle = {
    backgroundColor: token.colorBgElevated,
    borderRadius: token.borderRadiusLG,
    boxShadow: token.boxShadowSecondary
    // width: "140%"
  }

  const handleApply = () => {
    setOpen(false)
    setSelectedEquipmentStatus(values)
    onEquipStatusSelection(values)
  }

  const handleResetFilter = () => {
    setSelectedEquipmentStatus("")
    setValues("")
    onEquipStatusSelectionClear()
  }

  const onOpenChange = (open) => {
    if (!open) {
      setValues(selectedEquipmentStatus)
    }
    setOpen(open)
  }

  const onCaretakerOptionChange = (e) => {
    setValues(e.target.value)
  }
  return (
    <>
      <Dropdown
        open={open}
        onOpenChange={onOpenChange}
        destroyPopupOnHide
        menu={{ items: [] }}
        trigger={["click"]}
        className="flex-1"
        dropdownRender={_ => (
          <div style={contentStyle}>
            <div className="pt-2 px-4 mr-8">
              <Radio.Group onChange={onCaretakerOptionChange} value={values}>
                <Space direction="vertical">
                  {Object.values(EquipmentStatus)?.map(val => (
                    <Radio key={val} value={val}>
                      {val}
                    </Radio>
                  ))}
                </Space>
              </Radio.Group>
            </div>
            <Divider className="my-3" />

            <div className="px-8 pb-4 flex justify-center">
              <Button type="primary" onClick={handleApply}>
                Done
              </Button>
            </div>
          </div>
        )}>
        <Button
          style={{
            // minWidth: 150,
            height: "inherit"
          }}>
          <div className="flex items-center justify-center gap-2">
            {(selectedEquipmentStatus?.length && (
              <>
                <Typography.Link>Equipment Status : {selectedEquipmentStatus}</Typography.Link>
                <Typography.Link>
                  <DownOutlined />
                </Typography.Link>
              </>
            )) || (
              <>
                Equipment Status
                <DownOutlined />
              </>
            )}
          </div>
        </Button>
      </Dropdown>
      <Space.Compact
        className={`border card light`}
        onClick={handleResetFilter}
        style={{ borderWidth: 1 }}>
        <span className="secondary px-4 pt-2">
          <CloseOutlined />
        </span>
      </Space.Compact>
    </>
  )
}

export default EquipmentStatusDropdown