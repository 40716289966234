import React, { useState } from "react"
import { NavLink, useNavigate, useLocation } from "react-router-dom"

import { useDispatch, useSelector } from "react-redux"

import { Input, Layout, Button, Space, Grid, Avatar, AutoComplete, Dropdown } from "antd"

import { MenuOutlined } from "@ant-design/icons"
// import { darkModeAction } from "../redux/actions/themeActions"
import { prevUserLogoutAction } from "../redux/actions/userActions"

import Navigation from "../data/Navigation"
import { Mixpanel } from "../utils/mixpanel"
import ProfileAvatar from "../utils/ProfileAvatar"

const { Search } = Input
const { Header } = Layout
const { useBreakpoint } = Grid

const items = [
  {
    key: "2",
    label: (
      <NavLink className="no-underline" to="/patient" onClick={() => Mixpanel.track("Patient Tab Clicked")}>
        Patient
      </NavLink>
    )
  },
  {
    key: "4",
    label: (
      <NavLink className="no-underline" to="/setting" onClick={() => Mixpanel.track("Setting Clicked")}>
        Settings
      </NavLink>
    )
  }
]

const generateOptions = arr => {
  const result = []
  arr.map(i => {
    if (i.children) {
      generateOptions(i.children)
    } else {
      result.push({
        value: i.key,
        label: i.label
      })
    }
    return i
  })
  return result
}

const Navbar = ({ sidebarHidden, setSidebarHidden, sidebarCollapsed, setSidebarCollapsed }) => {
  const [searchVal, setSearchVal] = useState("")

  const navigate = useNavigate()
  const location = useLocation()
  const screens = useBreakpoint()
  const isMobile = !screens?.lg


  const dispatch = useDispatch()
  const { userInfo } = useSelector(state => state.userInfo)
  // const { isDark } = useSelector(state => state.themeConfig)

  const handleLogout = e => {
    Mixpanel.track("Logout Clicked")
    e.preventDefault()
    // localStorage.clear()
    Mixpanel.reset()
    dispatch(prevUserLogoutAction())
  }

  const handleOptionSelect = val => {
    if (location.pathname !== val) {
      navigate(val)
      setSearchVal("")
    }
  }

  // const themeTogglerImg = isDark ? "/assets/sun-icon.png" : "/assets/moon-icon.png"

  const handleToggleSidebar = () => {
    if (isMobile) {
      setSidebarHidden(false)
      setSidebarCollapsed(false)
    } else {
      setSidebarCollapsed(!sidebarCollapsed)
    }
  }

  return (
    <Header className="sticky-top shadow-sm" style={{ zIndex: 2 }}>
      <Space className="navbar-left">
        {(isMobile && sidebarHidden) ? (
          <Button
            shape="circle"
            icon={<MenuOutlined />}
            className="sidebar-trigger"
            onClick={handleToggleSidebar}
          />
        ) : null}
        <AutoComplete
          value={searchVal}
          className="mb-3 hidden sm:inline-block"
          options={generateOptions(Navigation)}
          onSelect={handleOptionSelect}>
          <Search placeholder="Search..." />
        </AutoComplete>
      </Space>
      <div className="navbar-right flex items-center gap-3">
        <Dropdown
          trigger={["click"]}
          menu={{
            items: [
              {
                key: "1",
                label: (
                  <h6 className="capitalize mb-0 pb-2 text-lg font-medium border-bottom border-current">
                    {userInfo !== null
                      ? `${userInfo?.firstName} ${userInfo?.lastName}`
                      : "John Doe"}
                  </h6>
                )
              },
              ...items,
              {
                key: "5",
                label: (
                  <a href="" className="no-underline" onClick={handleLogout}>
                    Logout
                  </a>
                )
              }
            ]
          }}>
          <Space className="dropdown-trigger" align="center">
            <ProfileAvatar size={40} user={userInfo} />
            <span className="flex flex-col justify-center items-start">
              <h6 className="capitalize text-lg hidden sm:block">
                {userInfo !== null ? `${userInfo?.firstName}` : "John"}
              </h6>
              <span className="h-4 text-sm capitalize hidden sm:flex sm:justify-center sm:items-start">{userInfo !== null ? `${userInfo?.orgName}` : ''}</span>
            </span>
          </Space>
        </Dropdown>
      </div>
    </Header>
  )
}

export default Navbar
