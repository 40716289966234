import React, { useState, useEffect } from "react"
import Loading from "../components/Loading/Loading"
import { Helmet } from "react-helmet"
import PlanView from "@fitpeo/fit-plan-viewer"
import { TempAxiosGet } from "../utils/TokenStorage"
import { Typography } from "antd"
import { getDateOfService } from "../utils/getDateOfService"

const MobileCCM = () => {
  const [patientInfoDetail, setPatientInfoDetail] = useState(null)
  const [carePlanData, setCarePlanData] = useState()
  const [loading, setLoading] = useState(true)
  const [firstCall, setFirstCall] = useState(false)
  const [providers, setProviders] = useState(null)
  const [practitioners, setPractitioners] = useState(null)
  const [icdCodes, setIcdCodes] = useState(null)

  // useEffect(() => {
  //     setLoading(true)
  //     const timer = setTimeout(() => {
  //         if (window.value !== undefined && window.token !== undefined && firstCall === false) {
  //                 //dispatch(getPatientInfo({ patientId: window.value.patientId }));
  //                 getPatientData(window.value.patientId)
  //                 getCcmPlan(window.value.patientId)
  //                 setFirstCall(true)
  //         }
  //     }, 1000);
  //     setLoading(false)
  //     return () => clearTimeout(timer);

  // }, [window.token]);

  const convertCareplanToV2 = plan => {
    if (!plan) return
    if (plan?.body) return plan?.body
    else return Object.values(plan)
  }

  useEffect(() => {
    setTimeout(() => {
      if (window.value !== undefined && window.token !== undefined && firstCall === false) {
        getPatientData(window.value.patientId)
        setFirstCall(true)
      }
    }, 1000)
  }, [window.token]) //eslint-disable-line

  const getCcmPlan = async patientID => {
    const res = await TempAxiosGet(
      window.token,
      `/api/ccm/careplan/comprehensive/latest/${patientID}/fetch`
    )
    setCarePlanData(res?.details)

    if (res?.details !== null) {
      if (!res?.details?.plan?.planIcds) getIcds(patientID)
      if (res?.details?.plan?.providers) {
        setProviders(res?.details?.plan?.providers)
        setPractitioners(res?.details?.plan?.practitioners)
      } else if (res?.details?.plan?.provider) {
        setProviders([`${res?.details?.plan?.provider?.providerFirstName && res?.details?.plan?.provider?.providerFirstName !== "" ? " " + res?.details?.plan?.provider?.providerFirstName : ""}
        ${res?.details?.plan?.provider?.providerMiddleName && res?.details?.plan?.provider?.providerMiddleName !== "" ? " " + res?.details?.plan?.provider?.providerMiddleName : ""} 
        ${res?.details?.plan?.provider?.providerLastName && res?.details?.plan?.provider?.providerLastName !== "" ? " " + res?.details?.plan?.provider?.providerLastName : ""}`])
      } else {
        getProvider(patientID)
      }
    }
  }

  const getPatientData = async patientId => {
    const res = await TempAxiosGet(window.token, `/api/cs/patients/${patientId}/profile`)
    if (res?.status === 200) {
      setPatientInfoDetail(res?.details)
      await getCcmPlan(patientId)
      setLoading(false)
    }
  }

  const getIcds = async patientID => {
    const res = await TempAxiosGet(window.token, `/api/cs/patients/diagnostic/${patientID}/fetch`)
    setIcdCodes(res?.details?.map(({ icdCode }) => icdCode))
  }

  const getProvider = async patientID => {
    const res = await TempAxiosGet(window.token, `/api/cs/patients/monitoring/${patientID}/fetch`)
    let provider = []
    let practitioner = []

    if (res?.details?.ccm?.[0]) {
      provider = res?.details?.ccm?.[0]?.providerId ? [` ${res?.details?.ccm?.[0]?.providerfirstName}
      ${res?.details?.ccm?.[0]?.providerMiddleName && res?.details?.ccm?.[0]?.providerMiddleName !== "" ? " " + res?.details?.ccm?.[0]?.providerMiddleName : ""} 
      ${res?.details?.ccm?.[0]?.providerLastName}`] : []
    }
    if (res?.details?.ccm?.[0]) {
      practitioner = res?.details?.ccm?.[0]?.practitionerId ? [` ${res?.details?.ccm?.[0]?.practitionerfirstName}
      ${res?.details?.ccm?.[0]?.practitionerMiddleName && res?.details?.ccm?.[0]?.practitionerMiddleName !== "" ? " " + res?.details?.ccm?.[0]?.practitionerMiddleName : ""} 
      ${res?.details?.ccm?.[0]?.practitionerLastName}`] : []
    }

    setProviders(provider)
    setPractitioners(practitioner)
  }

  return (
    <>
      <Helmet>
        <script>
          {`
                function fillDetails(jsonData) {
                var content = JSON.parse(decodeURIComponent(jsonData));
                // var content = {"token":"eyJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJzZWxmIiwic3ViIjoiMzk3Y2E4YzMtZjllNy00YTliLWFjN2UtYzk0MzQ3OWQ1Yzc4IiwiZXhwIjoxNzE4MDMyMzM5LCJpYXQiOjE3MTgwMjg3MzksInNjb3BlIjoicHJvdmlkZXIifQ.QRpTIcb73AeOjTtiMJNLTwJRQypoBKcQNeJ_dhjoLD8fkuRvpqG_hfa3oPyIl0i3SIPTJzlk7U2QTe2Dm8B-h6sQjYvkdEjN9-XsjwvjuHW0tzFBOI5c9-sJ_g5znUhNyYcCUwMtU_7EgwW-PHQZRYqgIphko2x8p_Yb_93m7AMferFluQuvvqIxPbsTaM9cuJG4Eap5RTNs-A3RZRj2MhCCpYHAsIpVfM_G0oEP5IPkpq4NsI7IHkmF8SavzZv20rOI5LVx5LWfEc20PAzuhCadLdCVT3ZXqHnRmLPXcxT--XNRG5vcyEX-2tlEetFuzy1duO4b1hZiFbpJREgYXw", patientId:"8e9d8b22-e487-4226-829d-3009d7c98af1"};

                window.isMobileVersion = true;
                window.value = content;
                window.token = content.token;
                }`}
        </script>
      </Helmet>
      {loading & !carePlanData ? (
        <Loading />
      ) : (
        (patientInfoDetail !== undefined && carePlanData && (
          <PlanView
            type="ccm"
            planDate={carePlanData?.planDate}
            providers={providers}
            practitioners={practitioners}
            patientInfoDetail={patientInfoDetail}
            plan={convertCareplanToV2(carePlanData?.plan)}
            planIcds={carePlanData?.plan?.planIcds ?? icdCodes}
            addedBy={carePlanData?.plan?.addedBy}
            serviceDate={getDateOfService(carePlanData?.planDate)}
          />
        )) || (
          <Typography.Title level={4} className="mt-3 text-center">
            No plan found
          </Typography.Title>
        )
      )}
    </>
  )
}

export default MobileCCM
