import React from "react"

import { Card, Input, Row, Col} from "antd"

import { useDispatch, useSelector } from "react-redux"
import PersonalDetails from "./PersonalDetails"

// import imageCompression from "browser-image-compression"

// import { base64ImageConvertor } from "../layout/utils/imageConvertor"

// import { UpdateProfileAction } from "../../redux/actions/userActions"
import formatPhoneNumber from "../../utils/formatPhoneNumber"

const Profile = () => {
  const dispatch = useDispatch()
  const { userInfo } = useSelector(state => state.userInfo)

  // const handleFileChange = async e => {
  //   const file = e.target.files[0]
  //   if (file !== undefined) {
  //     const options = {
  //       maxSizeMB: 1,
  //       useWebWorker: true,
  //       maxWidthOrHeight: 1920
  //     }
  //     try {
  //       const compressedFile = await imageCompression(file, options)
  //       const base64Image = await base64ImageConvertor(compressedFile)
  //       const profileImage = await base64Image.replace("data:image/png;base64,", "")
  //       setProfilePic(profileImage)
  //       dispatch(UpdateProfileAction(profileImage))
  //     } catch (error) {
  //       addToast("we are unable to process at this moment.", {
  //         appearance: "error",
  //         autoDismiss: true
  //       })
  //     }
  //   }
  // }

  return (
    <Row gutter={[16, 16]}>
    <Col sm={24} lg={8}>
      <PersonalDetails />
    </Col>
    <Col sm={24} lg={16}>
    <Card title="">
      <div className="flex justify-content-between align-items-center mb-4">
        <div>
          <h2 className="text-2xl">Contact Details</h2>
        </div>
      </div>
      <Row gutter={16}>
        <Col sm={24} lg={24} className="mb-4 w-full">
          <label htmlFor="mobileNumber" className="mb-2">
            Mobile Number
          </label>
          <Input readOnly size="large" id="mobileNumber" value={formatPhoneNumber(userInfo?.mobileNumber)} />
        </Col>
        <Col sm={24} lg={24} className="mb-4">
          <label htmlFor="email" className="mb-2">
            Email
          </label>
          <Input readOnly size="large" id="email" value={userInfo.email} />
        </Col>
      </Row>
    </Card>    
    </Col>
    </Row>
  )
}

export default Profile
